<template lang="pug">
	div
		div(class="row")
			div(class="col s12")
				separator(text="DGBRT Ticketscanner herunterladen & installieren")
		div(class="row")
			div(class="col s12")
				div(class="contentbox small-borders")
					div(class="appstore-badges")
						div
							a(alt="Download from Google Play Store" href="https://play.google.com/store/apps/details?id=de.dgbrt.dgbrt_ticket_scanner" target="_blank")
								img(title="Google Play Store Badge" class="appstore-badge" v-bind:src="getImgUrl('appstores/google-play-badge.png')")
						div
							a(alt="Download from Apple App Store" href="https://apps.apple.com/de/app/dgbrt-ticketscanner/id1609615199" target="_blank")
								img(title="Apple App Store Badge" class="appstore-badge" v-bind:src="getImgUrl('appstores/apple-appstore-badge.png')")

		div(class="row")
			div(class="col s12")
				separator(text="DGBRT Ticketscanner einrichten")
		div(class="row")
			div(class="col s12")
				div(class="contentbox small-borders")
					center
						a(class="btn z-depth-0 download-button" target="_blank" href="/pdf/2024-02-29-DGBRT_HowTo_TicketScanner.pdf") Handbuch herunterladen

</template>

<style scoped>
.small-borders {
	padding: 1em;
}


.download-button {
	background-color: #e1783e;
	border-radius: 0px;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	font-size: 1.5rem;
	font-weight: bold;
	height: auto;
	white-space: nowrap;
}

.appstore-badges {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

img.appstore-badge {
	width: auto;
	max-width: 300px;
}

@media screen and (max-width: 850px) {
	.appstore-badges {
		flex-direction: column;
	}
}

@media screen and (max-width: 600px) {
	.download-button {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		font-size: 1rem;
	}

	img.appstore-badge {
		max-width: 200px;
	}
}

@media screen and (max-width: 450px) {
	.download-button {
		padding-top: 1rem;
		padding-bottom: 1rem;
		white-space: inherit;
	}
}

</style>

<script>
import Separator from '@/components/Separator';
import UrlMixin from '@/mixins/UrlMixin';


export default {
	mixins: [ UrlMixin ],
	components: {
		'separator': Separator
	}
}
</script>